<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_arrivalScheduleAdd" />
      <v-form ref="form" lazy-validation>
        <!-- 検索項目-->
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="commonInput.suppliersSelected"
                :items="commonInput.supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                :rules="[rules.inputRequired]"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷元-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                :label="$t('入荷元')"
                class="txt-single"
                persistent-hint
                v-model="commonInput.arrivalSelected"
                :items="commonInput.arrivalList"
                :hint="setSrhArrival()"
                :search-input.sync="commonInput.searchArrival"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷No-->
            <div class="textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                maxlength="50"
                v-model="commonInput.inListNo"
                :label="$t('label.lbl_slipNo')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 入荷予定日付-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="commonInput.arrivalScheduleDate"
                :label="$t('label.lbl_arrivalScheduleDate')"
                :rules="[rules.inputRequired, rules.yyyymmdd]"
                @change="changeDate"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <!--カレンダーボタン領域-->
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="commonInput.arrivalScheduleDateCal"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <div style="float: left">
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>

              <!--ボタン領域-->
              <div class="btn-area">
                <!-- 追加ボタン-->
                <v-btn color="primary" class="api-btn" v-on:click="add" :disabled="isDoAddButton">
                  {{ $t("btn.btn_add") }}
                </v-btn>

                <!-- 登録ボタン-->
                <v-btn id="btn-pack" class="post-btn" value="1" @click="rcvScheAdd">
                  {{ $t("btn.btn_insert") }}
                </v-btn>
                <ConfirmDialog
                  :isShow.sync="ConfirmDialog.isOpen"
                  :message="ConfirmDialog.message"
                  :okAction="addRcvSch"
                  :redMessage="ConfirmDialog.redMessage"
                  :screenFlag="ConfirmDialog.screenFlag"
                  :changeFlag="ConfirmDialog.changeFlag"
                />
              </div>
            </div>
          </v-row>
          <!-- <v-row> -->
          <!--2段目-->
          <!-- <div class="search-row-exeBtn first-search-item"> -->
          <!-- グループ-->
          <!-- <div class="search-autocomplete" style="float: left"> -->
          <!-- <v-autocomplete
                  dense
                  :label="$t('グループ')"
                  class="txt-single"
                  persistent-hint
                  v-model="commonInput.groupSelected"
                  :items="commonInput.groupList"
                  :hint="setSrhGroup()"
                > -->
          <!-- アイテム一覧の表示 -->
          <!-- <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template> -->
          <!-- * -->
          <!-- </v-autocomplete> -->
          <!-- <v-text-field
                  outlined
                  dense
                  maxlength="100"
                  v-model="commonInput.txt_groupName"
                  :label="$t('グループ')"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  clearable
                />
              </div> -->
          <!-- 空白 -->
          <!-- <span class="item-spacer" style="float: left">&nbsp;</span> -->
          <!-- 部品要求者-->
          <!-- <div class="search-autocomplete" style="float: left">
                <v-autocomplete
                  dense
                  :label="$t('部品要求者')"
                  class="txt-single"
                  persistent-hint
                  v-model="commonInput.partsRequesterSelected"
                  :items="commonInput.partsRequesterList"
                  :hint="setSrhPartsRequester()"
                > -->
          <!-- アイテム一覧の表示 -->
          <!-- <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template> -->
          <!-- * -->
          <!-- </v-autocomplete>
              </div> -->
          <!-- </div> -->
          <!-- </v-row> -->
        </v-container>
      </v-form>

      <!-- データテーブル（項目） -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="620px"
          >
            <!-- 商品コード/品名-->
            <template v-slot:[`item.productCd`]="{ item, index }" style="padding: 0 5px">
              <div id="table-productCd">
                <v-autocomplete
                  v-model="item.productCd"
                  :items="item.productList"
                  @change="(event) => changeProductNm(event, item, index)"
                  @focus="addDummy(item)"
                  :hint="checkProductCd(item, index)"
                  :search-input.sync="item.search"
                  persistent-hint
                  dense
                  v-bind:class="{ isD: item.isNew }"
                  class="cursorProcd"
                  :ref="'cursorProcd' + index"
                  :rules="[$inputRules.isRequiredRow(headerItems, item), inputProductValid(item)]"
                  :error-messages="item.requiredItemCheckMsg"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>
            <!-- 入荷予定数-->
            <!-- <template v-slot:[`item.productQty`]="{ item, index }">
              <div id="table-productQty">
                <v-text-field
                  class="input-number"
                  dense
                  outlined
                  v-model="item.productQty"
                  @click="clickPop(item.productQty)"
                  @change="changeQty(item, index)"
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheck,
                    rules.limitNumLength,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'productQtyCheck' + index"
                  :error-messages="item.requiredItemCheckMsg"
                />
              </div>
            </template> -->
            <!-- 入り数-->
            <template v-slot:[`item.inQty`]="{ item }">
              <div id="table-inQty">
                <v-text-field
                  class="input-number"
                  outlined
                  dense
                  v-model="item.inQty"
                  maxlength="9"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheck,
                    rules.limitNumLength,
                  ]"
                />
              </div>
            </template>
            <!-- ロット -->
            <template v-slot:[`item.lot`]="{ item, index }">
              <div id="table-lot">
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.lot"
                  maxlength="20"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[rules.serialCheck(item.serial, inputList, index, item.productCd)]"
                  :disabled="item.lotFlg != '1'"
                />
              </div>
            </template>
            <!-- S/N -->
            <template v-slot:[`item.serial`]="{ item, index }">
              <div id="table-serial">
                <v-text-field
                  class="text-box"
                  outlined
                  dense
                  v-model="item.serial"
                  maxlength="32"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[rules.serialCheck(item.serial, inputList, index, item.productCd)]"
                  :disabled="item.serialFlg != '1'"
                />
              </div>
            </template>
            <!-- 納品種別-->
            <!-- <template v-slot:[`item.deliveryDiv`]="{ item }">
              <v-autocomplete
                v-model="item.deliveryDiv"
                :items="commonInput.deliveryDivList"
                persistent-hint
                dense
                item-text="name"
                item-value="id"
                return-object
              > -->
            <!-- アイテム一覧の表示 -->
            <!-- <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template> -->
            <!-- * -->
            <!-- </v-autocomplete>
            </template> -->
            <!-- 備考-->
            <!-- <template v-slot:[`item.remarks`]="{ item }">
              <v-text-field
                outlined
                dense
                v-model="item.remarks"
                class="text-box"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
              />
            </template> -->
            <!-- 詳細情報ボタン -->
            <template v-slot:[`item.detailInfo`]="{ item }">
              <v-btn small @click="openDetailsAdd(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>

            <!-- 削除-->
            <template v-slot:[`item.deleteData`]="{ item }">
              <v-btn small @click="deleteItem(item)" text>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-form>
      <v-container fluid style="padding-top: 0px">
        <v-data-table
          id="listSumDataSpecial"
          class="tableData"
          fixed-header
          :items="inputSumList"
          :headers="headerItems"
          disable-filtering
          disable-sort
          disable-pagination
          :hide-default-footer="true"
          hide-default-header
          height="49px"
        >
        </v-data-table>
      </v-container>
      <!-- 付帯情報/ロケーション予約ポップアップ-->
      <v-dialog v-model="dialogAddProudct" :max-width="800">
        <v-card>
          <v-card-title id="sub-bar">
            <span id="lbl-screen-name">{{ $t("label.lbl_arrivalScheduleRegistDetail") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    v-model="location"
                    :label="$t('label.lbl_location')"
                    clear-icon="mdi-close-circle"
                    readonly
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-btn @click="addLocation()" text>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-h5"
                  >{{ $t("label.lbl_accessoryInfo") }}
                  <v-btn @click="addAccItem" text>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <!-- 付帯情報入力箇所 -->
              <v-row>
                <v-data-table
                  hide-default-header
                  :headers="accHeader"
                  :items="accList"
                  disable-filtering
                  disable-pagination
                  disable-sort
                  :hide-default-footer="true"
                >
                  <template v-slot:[`item.accSelected`]="{ item }">
                    <v-autocomplete
                      v-model="item.accSelected"
                      :items="accKeys"
                      @change="(event) => changeAcc(event, item)"
                      outlined
                      dense
                    ></v-autocomplete>
                    <v-text-field v-show="false" v-model="item.accSelectedNm"></v-text-field>
                  </template>
                  <template v-slot:[`item.accValue`]="{ item }">
                    <v-text-field
                      outlined
                      dense
                      v-model="item.accValue"
                      maxlength="10"
                      clear-icon="mdi-close-circle"
                      clearable
                    />
                  </template>
                  <template v-slot:[`item.delete`]="{ item }">
                    <v-btn small @click="deleteAccItem(item)" text>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="api-btn" @click="close">
              {{ $t("btn.btn_close") }}
            </v-btn>
            <v-btn color="primary" class="api-btn" @click="save">
              {{ $t("btn.btn_insert") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 新製品登録ポップアップ-->
      <v-dialog v-model="dialogNewProduct" :max-width="600" persistent="" no-click-animation>
        <v-form ref="newProductForm" lazy-validation>
          <v-card>
            <v-card-title id="sub-bar">
              <span id="lbl-screen-name">{{ $t("label.lbl_newProductAdd") }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <div class="add-textbox-delivery">
                    <v-text-field
                      outlined
                      dense
                      v-model="popNewProductCd"
                      :label="$t('label.lbl_productCode')"
                      clear-icon="mdi-close-circle"
                      :rules="[rules.limitLength50, rules.inputRequired]"
                      clearable
                      :error-messages="alertMessageInUnit"
                    ></v-text-field>
                  </div>
                  <span class="require" style="padding-top: 20px">*</span>
                </v-row>
                <v-row>
                  <div class="add-textbox-delivery">
                    <v-text-field
                      outlined
                      dense
                      v-model="popNewProductAccessoryCd"
                      :label="$t('label.lbl_accessoryProduct')"
                      clear-icon="mdi-close-circle"
                      :rules="[rules.limitLength50]"
                      clearable
                      :error-messages="alertMessageInUnit"
                    ></v-text-field>
                  </div>
                </v-row>
                <v-row>
                  <div class="add-textbox-delivery">
                    <v-text-field
                      outlined
                      dense
                      v-model="popNewProductNm"
                      :label="$t('label.lbl_productCodeName')"
                      clear-icon="mdi-close-circle"
                      :rules="[rules.limitLength50, rules.inputRequired]"
                      maxlength="50"
                      clearable
                      :error-messages="alertMessageInUnit"
                    ></v-text-field>
                  </div>
                  <span class="require" style="padding-top: 20px">*</span>
                </v-row>
                <v-row>
                  <div class="add-textbox-delivery">
                    <v-text-field
                      outlined
                      dense
                      v-model="popInboundUnitQuantity"
                      :label="$t('label.lbl_inboundUnitQuantity')"
                      clear-icon="mdi-close-circle"
                      @change="changePop(popInboundUnitQuantity)"
                      @click="clickPop(popInboundUnitQuantity)"
                      :rules="[
                        rules.inputRequired,
                        rules.isNumber,
                        rules.limitNumLength,
                        rules.isRangeCheck,
                        rules.isInteger,
                      ]"
                      clearable
                      :ref="'popQuanRef'"
                      :error-messages="alertMessageInUnit"
                    ></v-text-field>
                  </div>
                  <span class="require" style="padding-top: 20px">*</span>
                </v-row>
                <!-- ロット有無/シリアル有無 -->
                <v-row>
                  <div class="add-listbox-delivery">
                    <v-autocomplete
                      dense
                      v-model="popNewProductLotFlg"
                      :items="lotPullList"
                      :label="$t('label.lbl_lotCnt')"
                      :rules="[rules.inputRequired]"
                      class="txt-single"
                      persistent-hint
                    >
                      <!-- アイテム一覧の表示 -->
                      <template slot="item" slot-scope="data">
                        <span class="test">
                          {{ data.item.text }}
                        </span>
                      </template>
                      <!-- * -->
                    </v-autocomplete>
                  </div>
                  <span class="require" style="padding-top: 10px">*</span>
                  <!-- 空白 -->
                  <span class="item-spacer" style="float: left">&nbsp;</span>
                  <div class="add-listbox-delivery">
                    <v-autocomplete
                      dense
                      v-model="popNewProductSerialFlg"
                      :items="lotPullList"
                      :label="$t('label.lbl_serialCnt')"
                      :rules="[rules.inputRequired]"
                      class="txt-single"
                      persistent-hint
                    >
                      <!-- アイテム一覧の表示 -->
                      <template slot="item" slot-scope="data">
                        <span class="test">
                          {{ data.item.text }}
                        </span>
                      </template>
                      <!-- * -->
                    </v-autocomplete>
                  </div>
                  <span class="require" style="padding-top: 10px">*</span>
                </v-row>
                <!-- 期限日有無/付属キー１有無/付属キー２有無 -->
                <v-row>
                  <div class="add-listbox-delivery">
                    <v-autocomplete
                      dense
                      v-model="popNewProductDueDateFlg"
                      :items="lotPullList"
                      :label="$t('label.lbl_dueDateCnt')"
                      :rules="[rules.inputRequired]"
                      class="txt-single"
                      persistent-hint
                    >
                      <!-- アイテム一覧の表示 -->
                      <template slot="item" slot-scope="data">
                        <span class="test">
                          {{ data.item.text }}
                        </span>
                      </template>
                      <!-- * -->
                    </v-autocomplete>
                  </div>
                  <span class="require" style="padding-top: 10px">*</span>
                  <!-- 空白 -->
                  <span class="item-spacer" style="float: left">&nbsp;</span>
                  <div class="add-listbox-delivery">
                    <v-autocomplete
                      dense
                      v-model="popNewProductLotSubkey1Flg"
                      :items="lotPullList"
                      :label="$t('label.lbl_lotSubkey1Cnt')"
                      :rules="[rules.inputRequired]"
                      class="txt-single"
                      persistent-hint
                    >
                      <!-- アイテム一覧の表示 -->
                      <template slot="item" slot-scope="data">
                        <span class="test">
                          {{ data.item.text }}
                        </span>
                      </template>
                      <!-- * -->
                    </v-autocomplete>
                  </div>
                  <span class="require" style="padding-top: 10px">*</span>
                  <div class="add-listbox-delivery">
                    <v-autocomplete
                      dense
                      v-model="popNewProductLotSubkey2Flg"
                      :items="lotPullList"
                      :label="$t('label.lbl_lotSubkey2Cnt')"
                      :rules="[rules.inputRequired]"
                      class="txt-single"
                      persistent-hint
                    >
                      <!-- アイテム一覧の表示 -->
                      <template slot="item" slot-scope="data">
                        <span class="test">
                          {{ data.item.text }}
                        </span>
                      </template>
                      <!-- * -->
                    </v-autocomplete>
                  </div>
                  <span class="require" style="padding-top: 10px">*</span>
                  <!-- 空白 -->
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="api-btn" @click="popNewProductClose">
                {{ $t("btn.btn_close") }}
              </v-btn>
              <v-btn color="primary" class="api-btn" @click="popNewProductSave">
                {{ $t("btn.btn_insert") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- 詳細情報画面ポップアップ -->
      <v-dialog v-model="openDatailsAdd" :max-width="600">
        <v-card>
          <v-card-title id="sub-bar" class="text-center">
            <div id="lbl-screen-name">{{ $t("詳細情報") }}</div>
          </v-card-title>
          <v-card-text class="pt-4">
            <v-form ref="detailPopup" lazy-validation>
              <v-container style="max-width: 500px; margin: 0 auto; padding: 0">
                <v-simple-table class="custom-table">
                  <thead>
                    <tr>
                      <th class="no-border">
                        <v-row style="font-size: 14px; font-weight: 400; color: rgba(0, 0, 0, 0.87)"
                          >品番/品名&nbsp;&nbsp;&nbsp;&nbsp;</v-row
                        >
                        <v-row style="font-size: 14px; font-weight: 400; color: rgba(0, 0, 0, 0.87)"
                          >管理番号&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-row
                        >
                      </th>
                      <th class="no-border text-right pr-0">
                        <v-btn
                          color="primary"
                          id="btn-search"
                          class="other-btn"
                          @click="
                            () => {
                              openDatailsAdd = false;
                            }
                          "
                        >
                          {{ $t("btn.btn_close") }}</v-btn
                        >
                        <v-btn
                          color="primary"
                          id="btn-search"
                          class="other-btn"
                          @click="addDetailsInfo()"
                          :disabled="false"
                        >
                          {{ $t("btn.btn_insert") }}</v-btn
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="highlight-column">{{ $t("label.lbl_warehouse") }}</td>
                      <td class="text-left">
                        <v-autocomplete
                          class="pt-2"
                          dense
                          v-model="detailDialog.warehouseSelected"
                          :items="detailDialog.warehouseList"
                          item-text="name"
                          item-value="id"
                          return-object
                          :rules="[]"
                        >
                        </v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td class="highlight-column">ロケーション</td>
                      <td class="text-left">
                        <v-autocomplete
                          class="pt-2"
                          dense
                          v-model="detailDialog.location"
                          :items="detailDialog.locationList"
                          item-text="name"
                          item-value="id"
                          return-object
                          :rules="[]"
                        >
                        </v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td class="highlight-column">納品種別</td>
                      <td class="text-left">
                        <v-autocomplete
                          class="pt-2"
                          dense
                          v-model="detailDialog.deliveryType"
                          :items="detailDialog.deliveryTypeList"
                          item-text="name"
                          item-value="id"
                          return-object
                          :rules="[]"
                        >
                        </v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td class="highlight-column">グループ名</td>
                      <td class="text-left">
                        <v-text-field
                          class="txt-single pt-2"
                          v-model="detailDialog.txt_groupName"
                          :rules="[]"
                          dense
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="highlight-column">保管期限</td>
                      <td>
                        <div class="d-flex align-center">
                          <v-text-field
                            dense
                            v-model="detailDialog.retentionPeriod"
                            class="txt-single date-style pt-2"
                            @change="changeRetentionPeriod('retentionPeriodCal', $event)"
                            :rules="[]"
                            clear-icon="mdi-close-circle"
                            clearable
                          ></v-text-field>
                          <v-menu
                            v-model="detailDialog.dateMenu"
                            :close-on-content-click="false"
                            :nudge-right="-100"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            class="date-calendar"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" text x-small>
                                <v-icon>mdi-calendar</v-icon>
                              </v-btn>
                            </template>
                            <v-date-picker
                              v-model="detailDialog.retentionPeriodCal"
                              @input="detailDialog.dateMenu = false"
                            />
                          </v-menu>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="highlight-column">{{ $t("label.lbl_price") }}</td>
                      <td class="text-center" style="vertical-align: middle">
                        <v-text-field
                          class="txt-single date-style pt-2 mx-auto right-align-input"
                          dense
                          v-model="detailDialog.formattedAmount"
                          @input="
                            (value) =>
                              commonUtil.amountHandleInput(
                                this,
                                value,
                                'detailDialog.formattedAmount',
                                'detailDialog.amount'
                              )
                          "
                          :rules="[]"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="highlight-column">通貨単位</td>
                      <td class="text-left">
                        <v-autocomplete
                          class="pt-2"
                          dense
                          v-model="detailDialog.monetaryUnit"
                          :items="detailDialog.monetaryUnitList"
                          return-object
                          :rules="[]"
                        >
                        </v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td class="highlight-column">コントロールナンバー</td>
                      <td>
                        <div class="d-flex align-center">
                          <v-text-field
                            dense
                            v-model="detailDialog.controlNumber"
                            class="txt-single pt-2"
                            :rules="[]"
                            clear-icon="mdi-close-circle"
                            clearable
                          ></v-text-field>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="highlight-column">仕様通知ナンバー</td>
                      <td>
                        <div class="d-flex align-center">
                          <v-text-field
                            dense
                            v-model="detailDialog.notificationSpecificationNumber"
                            class="txt-single pt-2"
                            :rules="[]"
                            clear-icon="mdi-close-circle"
                            clearable
                          ></v-text-field>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="highlight-column">部品要求者</td>
                      <td class="text-left">
                        <v-autocomplete
                          class="pt-2"
                          dense
                          v-model="detailDialog.partsRequester"
                          :items="detailDialog.partsRequesterList"
                          item-text="name"
                          item-value="id"
                          return-object
                          :rules="[]"
                        >
                        </v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td class="highlight-column">{{ $t("label.lbl_notes") }}</td>
                      <td>
                        <div class="d-flex align-center">
                          <v-textarea
                            class="txt-single pt-2"
                            v-model="detailDialog.remarks"
                            outlined
                            no-resize
                            rows="4"
                            :rules="[]"
                          ></v-textarea>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 戻るボタンのダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :redMessage="infoDialog.redMessage"
        :homePageFlag="infoDialog.homePageFlag"
        :rcvSchHacListFlg="infoDialog.rcvSchHacListFlg"
        :rcvSchHacAddFlg="infoDialog.rcvSchHacAddFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :okAction="init"
      />
      <v-dialog v-model="dialogLocation" :max-width="1000">
        <LocationDialog
          :isOpenDetailDialog.sync="dialogLocation"
          v-on:setLocation="saveLocation"
          :locInfo="locInfo"
          v-on:closePopup="closePopup"
        />
      </v-dialog>
      <CheckDialog
        :isShow.sync="checkDialog.isOpen"
        :message="checkDialog.message"
        :screenFlag="checkDialog.screenFlag"
        :okAction="checkDialog.okAction"
        :redMessage="checkDialog.redMessage"
        :changeFlag="checkDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import LocationDialog from "../../views/common/Location";
import NavBar from "../../components/NavBar.vue";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";
import CheckDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_RCV_011,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    LocationDialog,
    CheckDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // 共通入力
    commonInput: {
      // 取引先
      suppliersSelected: "",
      // 取引先プルダウン
      supplierList: [],
      // 入荷元プルダウン
      arrivalSelected: "",
      // 入荷元プルダウン
      arrivalList: [
        {
          text: "HACT（ホンダアクセス栃木受入）",
          value: "01",
          name: "ホンダアクセス栃木受入",
        },
      ],
      // 入荷No
      inListNo: "",
      // 入荷予定日付
      arrivalScheduleDateCal: "",
      arrivalScheduleDate: "",
    },

    detailDialog: {
      // 倉庫
      warehouseSelected: "",
      warehouseList: [
        {
          text: "倉庫A",
          value: "01",
          name: "倉庫A",
        },
        {
          text: "倉庫B",
          value: "02",
          name: "倉庫B",
        },
      ],
      // ロケーション
      location: "",
      locationList: [
        { id: "A001", name: "A001" },
        { id: "A002", name: "A002" },
      ],
      // 納品種別
      deliveryType: "",
      deliveryTypeList: [
        { id: "随試品発注", name: "随試品発注" },
        { id: "イベント品", name: "イベント品" },
      ],
      // グループ
      groupList: "",
      // 保管期限
      retentionPeriod: "",
      retentionPeriodCal: "",
      dateMenu: null,
      // 金額
      amount: "",
      formattedAmount: "",
      // 通貨単位
      monetaryUnit: "",
      monetaryUnitList: ["JPY", "USD", "EUR"],
      // コントロールナンバー
      controlNumber: "",
      // 通知仕様ナンバー
      notificationSpecificationNumber: "",
      // 部品要求者
      partsRequester: "",
      partsRequesterList: [
        { id: "Kensuke Hagiwara", name: "Kensuke Hagiwara" },
        { id: "Hiroaki Ishii", name: "Hiroaki Ishii" },
      ],
      // 備考
      remarks: "",
    },

    // 詳細画面ポップアップ
    openDatailsAdd: "",
    u_10: false,
    // 総件数
    totalCount: 0,
    // 合計リスト
    inputSumList: [],
    // 入力した品番・品名
    inputProduct: "",
    loadingCounter: 0,
    warehouse: { warehouse: "", location: "", productCd: "", qty: "" },
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
      referenceDate: "",
    },
    // 入庫単位数
    popInboundUnitQuantity: "",
    // ロケーション登録に渡す製品コード
    locProductCd: "",
    // ロケーションダイアログ
    dialogLocation: false,
    // 新製品登録ダイアログ
    dialogNewProduct: false,
    // 新製品コード
    popNewProductCd: "",
    // 新付帯品番
    popNewProductAccessoryCd: "",
    // 新製品名
    popNewProductNm: "",
    // 新ロットフラグ
    popNewProductLotFlg: "",
    // 新シリアルフラグ
    popNewProductSerialFlg: "",
    // 新期限日フラグ
    popNewProductDueDateFlg: "",
    // 新付属キー１フラグ
    popNewProductLotSubkey1Flg: "",
    // 新付属キー２フラグ
    popNewProductLotSubkey2Flg: "",
    // ロットプルダウン
    lotPullList: [],
    // 新商品を選択したインデックス
    newProductSelectedIndex: 0,
    // 一覧index
    editedIndex: -1,
    editedList: [],
    // 入荷予定登録ダイアログ
    dialogAddProudct: false,
    // メニュー
    openMenu: null,
    // 入荷予定日付キャレンダー
    dateMenu: false,
    // フォーカス移動フラグ
    moveFocusFlag: false,
    isOpenRcvScheAdd: false,
    // 追加ボタンdisabledフラグ
    isDoAddButton: false,
    checkDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // バリデーションチェックメッセージダイアログ
    alertMessageProCd: "",
    alertMessageProNm: "",
    alertMessageInUnit: "",
    productListIdx: -1,
    // 必須項目未入力エラーメッセージ
    requiredItemCheckMsg: "",
    // 倉庫未入力エラーメッセージ
    warehouseCheckMsg: "",
    // ロケーション未入力エラーメッセージ
    locationCheckMsg: "",
    // 初期データ
    defaultData: [],
    // 入荷予定品目一覧ヘッダ
    headerItems: [
      // No
      {
        text: "No",
        class: "listHeader",
        value: "No",
        width: "60px",
        sortable: false,
        align: "center",
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "100%",
        align: "center",
        class: "asta",
        sortable: false,
        isRequired: true,
      },
      // 入り数
      {
        text: i18n.tc("label.lbl_productHacQty"),
        value: "inQty",
        width: "200px",
        align: "center",
      },
      // 詳細情報
      {
        text: i18n.tc("label.lbl_dateilsInfo"),
        value: "detailInfo",
        width: "180px",
        align: "center",
      },
      // 削除
      { text: i18n.tc("btn.btn_delete"), value: "deleteData", width: "3%", align: "center" },
    ],
    dummyItemList: [],
    inputList: [],
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    defaultItem: {
      productNm: "",
      productCd: "",
      productHint: "",
      productAcc: "",
      productQty: "",
      locationHint: "",
      caseSu: "",
      caseSuHint: "",
      ballSu: "",
      ballSuHint: "",
      baraSu: "",
      baraSuHint: "",
      productList: [],
      lot: "",
      serial: "",
      dueDate: "",
      lotSubkey1: "",
      lotSubkey2: "",
      dueDateFlg: "0",
      lotSubkey1Flg: "0",
      lotSubkey2Flg: "0",
      serialFlg: "0",
      lotFlg: "0",
      locationSid: "",
      // warehouse: { warehouse: "", location: "", productCd: "", qty: "" },
      warehouse: "",
      accInfoList: [],
      accInfos: "",
      isNew: false,
      warehouseList: [],
      locationList: [],
      search: "",
      locaSearch: "",
    },
    accHeader: [
      { text: "キー", value: "accSelected", align: "center", width: "20%" },
      { text: "バリュー", value: "accValue", align: "center", width: "70%" },
      { text: "削除", value: "delete", align: "center", width: "10%" },
    ],
    accKeyMst: [],
    accList: [],
    accKeys: [],
    focusIdx: 0,
    location: "",
    locationSidList: [],
    isDisabledFreeReason: true,
  }),

  updated() {
    // 画面が再描画されたとき
    if (this.moveFocusFlag) {
      this.setCursor();
      this.moveFocusFlag = false;
    }
  },

  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);

      if (this.commonInput.suppliersSelected) {
        // 連続登録の場合
        this.infoDialog.isOpen = false;
        this.commonInput.freeReasonSelected = "";
        this.commonInput.arrivalStatusSelected = "01";
        this.commonInput.inListNo = "";
        this.inputList = [];
        this.inputList.location = "";
        this.commonInput.arrivalScheduleDate = dateTimeHelper.convertJST().substr(0, 10);
        this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertJST()
        );
        for (var i = 0; i < 10; i++) {
          this.defaultItem.No = i + 1;
          this.defaultItem.search = "";
          this.inputList.push(Object.assign({}, this.defaultItem));
        }
        // 一覧のバリデーションチェックをリセット
        this.$refs.editedList.resetValidation();
        // マスタ取得
        this.getMstCode();
        // 倉庫マスタ取得
        this.getWarehouse();
      } else {
        // 初期画面
        this.commonInput.freeReasonSelected = "";
        this.commonInput.arrivalStatusSelected = "01";
        this.commonInput.inListNo = "";
        this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertJST()
        );
        this.commonInput.arrivalScheduleDate = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.commonInput.processDivSelected = "01";
        for (var j = 0; j < 10; j++) {
          this.defaultItem.No = j + 1;
          this.inputList.push(Object.assign({}, this.defaultItem));
        }
        this.inputList.location = "";
        this.getMstCode();
        // 倉庫マスタ取得
        this.getWarehouse();
      }
      this.commonInput.groupSelected = "";
      this.commonInput.partsRequesterSelected = "";
      let sumList = [];
      sumList.push({
        No: "",
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: 0,
        lot: "",
        caseSu: "",
        ballSu: "",
        baraSu: "",
        warehouse: "",
        location: "",
        accInfos: "",
        deleteData: "",
      });
      this.inputSumList = [];
      this.inputSumList = sumList;
      // 項目初期化時に発生するバリデーションチェックをキャンセルする
      this.$refs.form.resetValidation();
    },
    /**
     * プルダウンチェンジイベント
     */
    // changeArrival(value) {
    //   // 既存品番/品名を選択時
    //   let val = this.commonInput.arrivalList.find((v) => v.value == value);
    //   if ((val ?? false) && value != "") {
    //     this.commonInput.productHintArrival = val.displayText;
    //     this.commonInput.searchArrival = val.displayText;
    //   }
    //   this.commonInput.arrivalList = this.commonInput.arrivalList.filter((v) => v.value == value);

    //   // 値が設定されていない場合はクリアする
    //   if (value == "" || value == null) {
    //     this.commonInput.productHintArrival = "";
    //   } else {
    //     // 処理なし
    //   }
    // },

    /**
     * 初期データ取得
     */
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.commonInput.supplierList = result[0];
          this.commonInput.supplierList = [
            {
              text: "ホンダアクセス",
              value: "1701600043",
              name: "ホンダアクセス",
              clientSid: "1701600043",
              clientCd: "9999016",
            },
          ];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      // 理由コード
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.IN_REASON_DIV);
      // 処理区分
      const processList = getParameter.getCodeMst(appConfig.CODETYPE.IN_PROCESS_DIV);
      // 有償無償区分
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);
      // 責任区分
      const blameList = getParameter.getCodeMst(appConfig.CODETYPE.IN_BLAME_DIV);
      // 有無区分
      const lotCnt = getParameter.getCodeMst(appConfig.CODETYPE.LOT_DIV);
      Promise.all([qualityList, reasonList, processList, freeCostList, blameList, lotCnt])
        .then((result) => {
          // 画面の初期値を設定します。
          this.commonInput.arrivalStatusList = result[0];
          this.commonInput.freeReasonList = result[1];
          this.commonInput.processDivList = result[2];
          this.commonInput.freeCostDivList = result[3];
          // 責任区分の最初のなしを削除
          result[4].splice(0, 1);
          this.commonInput.blameDivList = result[4];
          this.lotPullList = result[5];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * 入荷予定登録画面：メニューダイアログ処理
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 入荷予定登録画面：責任区別活性化処理
     */
    activateButton() {
      if (this.commonInput.arrivalStatusSelected == "") {
        return true;
      } else if (this.commonInput.arrivalStatusSelected == "01") {
        return true;
      } else if (this.commonInput.arrivalStatusSelected == "02") {
        return false;
      }
    },
    /**
     * 入荷予定登録画面：入荷予定日付を－１日
     */
    prevDate() {
      let date = new Date(this.commonInput.arrivalScheduleDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.commonInput.arrivalScheduleDateCal == null) {
        return;
      }
      if (this.commonInput.arrivalScheduleDateCal != toDate) {
        this.commonInput.arrivalScheduleDateCal = toDate;
      } else {
        this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() - 1)
        );
      }
    },

    /**
     * 入荷予定登録画面：入荷予定日付を＋１日
     */
    nextDate() {
      let date = new Date(this.commonInput.arrivalScheduleDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.commonInput.arrivalScheduleDateCal == null) {
        return;
      }
      if (this.commonInput.arrivalScheduleDateCal != toDate) {
        this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() + 2)
        );
      } else {
        this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() + 1)
        );
      }
    },

    /**
     * 倉庫取得
     */
    getWarehouse() {
      // ローディング画面表示ON
      // this.loadingCounter = 1;

      // const config = this.$httpClient.createGetApiRequestConfig();

      // config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // config.params.isMst = "0";
      // config.params.searchCategory = "0";

      // return new Promise((resolve, reject) => {
      //   this.$httpClient
      //     .secureGet(appConfig.API_URL.MST_WAREHOUSEBIZ_USESEARCH, config)
      //     .then((response) => {
      //       // console.debug("getWarehouse() Response", response);
      //       const jsonData = JSON.parse(JSON.stringify(response.data));

      //       if (jsonData.resCom.resComCode == "000") {
      //         const list = [];
      //         jsonData.resIdv.warehouses.forEach((row) => {
      //           list.push({
      //             text: row.warehouseLanguage[0].warehouseName,
      //             value: row.warehouseLanguage[0].warehouseSid,
      //             name: row.warehouseLanguage[0].warehouseName,
      //           });
      //         });
      //         this.warehouseList = list;
      //         resolve(response);
      //       } else {
      //         this.infoDialog.message = jsonData.resCom.resComMessage;
      //         this.infoDialog.title = appConfig.DIALOG.title;
      //         this.simpleDialog = true;
      //         // OK NGボタン
      //         this.infoDialog.firstPageFlag = true;
      //         reject();
      //       }
      //     })
      //     .catch((ex) => {
      //       this.infoDialog.message = ex;
      //       this.infoDialog.title = appConfig.DIALOG.title;
      //       this.simpleDialog = true;
      //       // OK NGボタン
      //       this.infoDialog.firstPageFlag = true;
      //       reject();
      //     })
      //     .finally(() => {
      //       // ローディング画面表示OFF
      //       this.loadingCounter = 0;
      //     });
      // });

      // ロケ
      this.locationSelected = "";
      // ロケプルダウン
      this.locationList = [
        {
          text: "A001",
          value: "01",
          name: "A001",
        },
        {
          text: "A002",
          value: "02",
          name: "A002",
        },
      ];
    },

    /**
     * 入荷予定登録画面：与えられた日付から年月日を文字列で返却処理
     */
    dateCalc(date) {
      const dt = new Date(date);
      let year = dt.toISOString().substr(0, 4);
      let month = dt.toISOString().substr(5, 2);
      let day = dt.toISOString().substr(8, 2);
      // ISO時間
      const todayISOTime = dt.toISOString().substr(11, 2);
      if (todayISOTime >= 15) {
        // 月末
        if (
          (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10) &&
          day == 31
        ) {
          // 31日まである月
          month = Number(month) + 1;
          day = "1";
        } else if ((month == 4 || month == 6 || month == 9 || month == 11) && day == 30) {
          // 30日まである月
          month = Number(month) + 1;
          day = "1";
        } else if (month == 2) {
          if (year % 4 == 0 && day == 29) {
            // 2月29日まである年
            month = Number(month) + 1;
            day = "1";
          } else if (year % 4 != 0 && day == 28) {
            // 2月28日まである年
            month = Number(month) + 1;
            day = "1";
          }
        } else if (month == 12 && day == 31) {
          //12月
          year = Number(year) + 1;
          month = "1";
          day = "1";
        } else {
          day = Number(day) + 1;
        }
        if (month < 10 && month.length != 2) {
          month = "0" + month;
        }
        if (day < 10 && day.length != 2) {
          day = "0" + day;
        }
      }
      return year + "-" + month + "-" + day;
      // return dt.toISOString().substr(0, 10);
    },

    /**
     * 入荷予定登録画面：yyyy/MM/ddに変換処理（カレンダコンポーネントの日付はyyyy-MM-ddのため）
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 入荷予定登録画面：付帯情報ツールチップ表示処理
     */
    setItemToList() {
      // 付帯情報詳細処理
      const accInfos = [];
      for (var i = 0; i < this.accList.length; i++) {
        if (this.accList[i].accSelectedNm !== undefined) {
          accInfos.push(this.accList[i].accSelectedNm + ":" + this.accList[i].accValue);
        }
      }
      return accInfos.join("\n");
    },

    /**
     * 入荷予定登録画面：付帯情報値変換処理(項目)
     */
    setItemTo() {
      // 付帯情報詳細処理
      const accInfoItem = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfoItem.push(this.accList[i].accSelectedNm);
      }
      return accInfoItem;
    },

    /**
     * 入荷予定登録画面：付帯情報値変換処理(内容)
     */
    setItemCon() {
      // 付帯情報詳細処理
      const accInfoCon = [];
      for (var i = 0; i < this.accList.length; i++) {
        accInfoCon.push(this.accList[i].accValue);
      }
      return accInfoCon;
    },

    /**
     * 入荷予定登録画面：行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.inputList.splice(this.editedIndex, 1);

      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].No = i + 1;
      }
      // console.debug("行削除");
      var proQty = commonUtil.zen2han(item.productQty).replaceAll(",", "");
      var totalNum = commonUtil.zen2han(this.totalCount).replaceAll(",", "");

      // 予定数合計から削除したレコードの数を引く
      this.totalCount = Number(totalNum) - Number(proQty);
      // カンマ編集
      this.totalCount = commonUtil.formatToCurrency(Number(this.totalCount));
      let sumList = [];
      sumList.push({
        No: "",
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
        lot: "",
        caseSu: "",
        ballSu: "",
        baraSu: "",
        warehouse: "",
        location: "",
        accInfos: "",
        deleteData: "",
      });
      this.inputSumList = [];
      this.inputSumList = sumList;

      this.$refs["productQtyCheck" + this.editedIndex].resetValidation();
      this.$refs["caseSuCheck" + this.editedIndex].resetValidation();
      this.$refs["ballSuCheck" + this.editedIndex].resetValidation();
      this.$refs["baraSuCheck" + this.editedIndex].resetValidation();
    },

    /**
     * 入荷予定登録画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      const suppliersSelected = this.commonInput.supplierList.find(
        (item) => item.value == this.commonInput.suppliersSelected
      );
      if (suppliersSelected) {
        return suppliersSelected.name;
      }
    },

    /**
     * 入荷予定登録画面：入荷元コードリストボックス取得処理
     */
    setSrhArrival() {
      const arrivalSelected = this.commonInput.arrivalList.find(
        (item) => item.value == this.commonInput.arrivalSelected
      );
      if (arrivalSelected) {
        return arrivalSelected.name;
      }
    },

    /**
     * 入荷予定登録画面：グループリストボックス取得処理
     */
    setSrhGroup() {
      const groupSelected = this.commonInput.groupList.find(
        (item) => item.value == this.commonInput.groupSelected
      );
      if (groupSelected) {
        return groupSelected.name;
      }
    },

    /**
     * 入荷予定登録画面：部品要求者リストボックス取得処理
     */
    setSrhPartsRequester() {
      const partsRequesterSelected = this.commonInput.partsRequesterList.find(
        (item) => item.value == this.commonInput.partsRequesterSelected
      );
      if (partsRequesterSelected) {
        return partsRequesterSelected.name;
      }
    },

    /**
     * 入荷予定登録画面：倉庫リストボックス取得処理
     */
    setSrhWarehouse() {
      const warehouseSelected = this.commonInput.warehouseList.find(
        (item) => item.value == this.commonInput.warehouseSelected
      );
      if (warehouseSelected) {
        return warehouseSelected.name;
      }
    },

    /**
     * 入荷予定登録画面：ロケリストボックス取得処理
     */
    setSrhLocation() {
      const locationSelected = this.commonInput.locationList.find(
        (item) => item.value == this.commonInput.locationSelected
      );
      if (locationSelected) {
        return locationSelected.name;
      }
    },

    /**
     * 入荷予定登録画面：入荷ステータスリストボックス取得処理
     */
    setStsArrival() {
      for (var i = 0; i < this.commonInput.arrivalStatusList.length; i++) {
        if (this.commonInput.arrivalStatusList[i].value == this.commonInput.arrivalStatusSelected) {
          return this.commonInput.arrivalStatusList[i].name;
        }
      }
    },
    /**
     * 入荷予定登録画面：商品コードリストボックス取得処理
     */
    checkProductCd(value, index) {
      //S/N活性・非活性判定
      for (var i = 0; i < this.inputList[index].productList.length; i++) {
        if (this.inputList[index].productList[i].value == this.inputList[index].productCd) {
          this.inputList[index].serialFlg = this.inputList[index].productList[i].serialFlg;
          this.inputList[index].lotFlg = this.inputList[index].productList[i].lotFlg;
          this.inputList[index].dueDateFlg = this.inputList[index].productList[i].dueDateFlg;
          this.inputList[index].lotSubkey1Flg = this.inputList[index].productList[i].lotSubkey1Flg;
          this.inputList[index].lotSubkey2Flg = this.inputList[index].productList[i].lotSubkey2Flg;
        }
      }
      return this.inputList[index].productHint;
    },

    /**
     * 明細の選択した商品コードに紐づく数量を返却するメソッド
     */
    getSelectedProductQuantity(index) {
      // 返却値
      let returnQuantity = 0;

      // inputListの商品コードを取得
      let inputListProductCd = this.inputList[index].productCd;

      if (!inputListProductCd) {
        // inputListの商品コードを取得

        // 選択した商品コードがproductListに何番目に入っているかを調べる。
        let selectedProductListIndex = -1;
        for (var i = 0; i < this.inputList[index].productList.length; i++) {
          if (inputListProductCd == this.inputList[index].productList[i].value) {
            selectedProductListIndex = this.inputList[index].productList[i].quantity;
            break;
          }
        }
        if (0 <= selectedProductListIndex) {
          // 選択している品番の数量を取得する。
          returnQuantity = this.inputList[index].productList[selectedProductListIndex].quantity;
        }
      }
      return returnQuantity;
    },

    /**
     * 入荷予定数0ダイアログ、OK
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     * 入荷予定登録画面：ケース数計算処理
     */
    setHintCase(value, index) {
      return this.inputList[index].caseSuHint;
    },

    /**
     * 入荷予定登録画面：ボール数計算処理
     */
    setHintBall(value, index) {
      return this.inputList[index].ballSuHint;
    },

    /**
     * 入荷予定登録画面：バラ数計算処理
     */
    setHintBara(value, index) {
      return this.inputList[index].baraSuHint;
    },

    /**
     * 入荷予定登録画面：行追加処理
     */
    add() {
      // 追加ボタンをdisabledに
      this.isDoAddButton = true;

      if (this.inputList.length != 0) {
        this.focusIdx = this.inputList[this.inputList.length - 1].No;
      } else {
        this.focusIdx = 0;
      }

      for (var i = 0; i < 10; i++) {
        this.defaultItem.No = this.focusIdx + i + 1;
        this.inputList.push(Object.assign({}, this.defaultItem));
      }
      // フォーカス移動フラグをONに
      this.moveFocusFlag = true;
      // xミリ秒待機したのちに、追加ボタンをenabledに
      setTimeout(this.enabledAddButton, 500);
      // console.debug("行追加");
    },

    /**
     * 追加ボタンを有効化する
     */
    enabledAddButton() {
      this.isDoAddButton = false;
    },

    /**
     * 入荷予定登録画面：入荷予定数、入力カンマ処理
     */
    changeQty(val, index) {
      // カンマを除去して数値項目に変換
      var proQty = commonUtil.zen2han(val.productQty).replaceAll(",", "");
      proQty = proQty.replace(/[^0-9]/gi, "");
      if (proQty.length > 9) {
        proQty = proQty.substring(0, 9);
      }
      // inputListの商品コードを取得
      let inputListProductCd = this.inputList[index].productCd;

      // 選択した商品の単位数を取得する
      let selectedProductQuantity = 0;
      for (var i = 0; i < this.inputList[index].productList.length; i++) {
        if (inputListProductCd == this.inputList[index].productList[i].value) {
          selectedProductQuantity = this.inputList[index].productList[i].quantity;
        }
      }
      if (!isNaN(Number(proQty))) {
        this.inputList[index].productQty = commonUtil.formatToCurrency(Number(proQty));
        if (0 < selectedProductQuantity) {
          // 入荷予定数が入力されている場合
          this.inputList[index].caseSu = commonUtil.formatToCurrency(
            Math.ceil(proQty / selectedProductQuantity)
          );
          this.inputList[index].caseSuHint = commonUtil.formatToCurrency(
            Math.ceil(proQty / selectedProductQuantity)
          );
          this.inputList[index].baraSu = commonUtil.formatToCurrency(
            proQty % selectedProductQuantity
          );
          this.inputList[index].baraSuHint = commonUtil.formatToCurrency(
            proQty % selectedProductQuantity
          );
          this.inputList[index].ballSu = commonUtil.formatToCurrency(
            Number(selectedProductQuantity)
          );
          this.inputList[index].ballSuHint = commonUtil.formatToCurrency(
            Number(selectedProductQuantity)
          );
          // カンマ編集して数量にセット
        } else {
          this.inputList[index].caseSu = "0";
          this.inputList[index].ballSu = "0";
          this.inputList[index].baraSu = "0";
        }
      }
      var totalNum = 0;
      this.totalCount = 0;
      if (!isNaN(Number(proQty)) || Number(totalNum)) {
        for (var j = 0; j < this.inputList.length; j++) {
          var proNum = commonUtil.zen2han(this.inputList[j].productQty).replaceAll(",", "");

          // 各行の予定数の合計を計算
          totalNum = Number(totalNum) + Number(proNum);
        }
        // カンマ編集
        this.totalCount = commonUtil.formatToCurrency(Number(totalNum));
      }

      let sumList = [];
      sumList.push({
        No: "",
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
        lot: "",
        caseSu: "",
        ballSu: "",
        baraSu: "",
        warehouse: "",
        location: "",
        accInfos: "",
        deleteData: "",
      });
      this.inputSumList = [];
      this.inputSumList = sumList;
    },

    /**
     * 入荷予定登録画面：入力カンマ処理 ケース数
     */
    changeCase(val, index) {
      var caseSu = commonUtil.zen2han(val.caseSu).replaceAll(",", "");
      caseSu = caseSu.replace(/[^0-9]/gi, "");
      if (caseSu.length > 9) {
        caseSu = caseSu.substring(0, 9);
      }
      if (!isNaN(Number(caseSu))) {
        this.inputList[index].caseSu = commonUtil.formatToCurrency(Number(caseSu));
      }
    },

    /**
     * 入荷予定登録画面：入力カンマ処理 ボール数
     */
    changeBall(val, index) {
      var ballSu = commonUtil.zen2han(val.ballSu).replaceAll(",", "");
      ballSu = ballSu.replace(/[^0-9]/gi, "");
      if (ballSu.length > 9) {
        ballSu = ballSu.substring(0, 9);
      }
      if (!isNaN(Number(ballSu))) {
        this.inputList[index].ballSu = commonUtil.formatToCurrency(Number(ballSu));
      }
    },

    /**
     * 入荷予定登録画面：入力カンマ処理 バラ数
     */
    changeBara(val, index) {
      var baraSu = commonUtil.zen2han(val.baraSu).replaceAll(",", "");
      baraSu = baraSu.replace(/[^0-9]/gi, "");
      if (baraSu == "-0") {
        baraSu = "0";
      }
      if (baraSu.length > 9) {
        baraSu = baraSu.substring(0, 9);
      }
      if (!isNaN(Number(baraSu))) {
        this.inputList[index].baraSu = commonUtil.formatToCurrency(Number(baraSu));
      }
    },

    /**
     * 入荷予定登録画面：入力カンマ処理 入庫単位数
     */
    changePop(popInboundUnitQuantity) {
      var pop = commonUtil.zen2han(popInboundUnitQuantity).replaceAll(",", "");
      if (popInboundUnitQuantity.length > 9) {
        popInboundUnitQuantity = popInboundUnitQuantity.substring(0, 9);
      }
      if (!isNaN(Number(pop))) {
        this.popInboundUnitQuantity = commonUtil.formatToCurrency(Number(pop));
      }
    },
    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },
    /**
     * 入荷予定登録画面：日付入力処理
     */
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.commonInput.arrivalScheduleDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.commonInput.arrivalScheduleDateCal = null;
      }
    },

    /**
     * 保管日付入力
     */
    changeRetentionPeriod(modelName, val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.detailDialog[modelName] = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.detailDialog[modelName] = null;
      }
    },

    /**
     * 入荷予定登録画面：閉じる処理
     */
    popNewProductClose() {
      // 値をクリアする。
      this.popNewProductNm = "";
      this.popNewProductCd = "";
      this.popNewProductAccessoryCd = "";
      this.popInboundUnitQuantity = null;
      this.popNewProductLotFlg = "";
      this.popNewProductSerialFlg = "";
      this.popNewProductDueDateFlg = "";
      this.popNewProductLotSubkey1Flg = "";
      this.popNewProductLotSubkey2Flg = "";
      // 品番/品名欄を初期化
      this.inputList[this.newProductSelectedIndex].productCd = "";
      this.inputList[this.newProductSelectedIndex].productHint = "";
      this.inputList[this.newProductSelectedIndex].isNew = false;
      this.inputList[this.newProductSelectedIndex].lotFlg = "0";
      this.inputList[this.newProductSelectedIndex].serialFlg = "0";
      this.inputList[this.newProductSelectedIndex].dueDateFlg = "0";
      this.inputList[this.newProductSelectedIndex].lotSubkey1Flg = "0";
      this.inputList[this.newProductSelectedIndex].lotSubkey2Flg = "0";
      this.dialogNewProduct = false;

      // 入力チェック結果をリセット
      this.$refs.newProductForm.reset();
    },

    /**
     * 付帯情報/ロケーション予約POPUP：閉じる処理
     */
    close() {
      this.dialogAddProudct = false;
      this.editedIndex = -1;
    },

    /**
     * 付帯情報/ロケーション予約POPUP：付帯情報/ロケーション予約POPUP処理
     */
    openAccData(item) {
      // 選択した明細のインデックスを取得
      this.editedIndex = this.inputList.indexOf(item);

      // indexから付帯情報を取得する。
      let accInfoList = this.inputList[this.editedIndex].accInfoList;

      // 付帯情報を表示用付帯情報に設定する。
      this.accList = accInfoList;

      // ロケーションを取得し設定する。
      let location = this.inputList[this.editedIndex].location;
      this.location = location;
      let locationSid = this.inputList[this.editedIndex].locationSid;
      this.locationSid = locationSid;

      // マスタを取得する。
      this.getMaster(item);

      this.dialogAddProudct = true;
    },

    /**
     * 付帯情報/ロケーション予約POPUP：行削除処理
     */
    deleteAccItem(item) {
      this.editedIndex = this.accList.indexOf(item);
      this.accList.splice(this.editedIndex, 1);

      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].No = i + 1;
      }
      // console.debug("行削除");
    },

    /**
     * 付帯情報/ロケーション予約POPUP：行追加処理
     */
    addAccItem() {
      this.accList.push({ accSelected: "", accValue: "" });
    },

    /**
     * 付帯情報/ロケーション予約POPUP：登録処理
     */
    save() {
      // ツールチップの値
      var accInfos = this.setItemToList();
      // 付帯情報の名前のリスト
      var accInfoItem = this.setItemTo();
      // 付帯情報の値のリスト
      var accValue = this.setItemCon();

      // 付帯情報リストをクリアする
      this.accList.splice(0);

      // 付帯情報の名前と値のリストを作成する
      let ancillaryInfoList = [];

      // 付帯情報分リストを回す
      for (var i = 0; i < accInfoItem.length; i++) {
        if (accInfoItem[i] === undefined) {
          continue;
        }
        // 付帯情報保持データ
        let ancillaryInfo = { accSelected: "", accSelectedNm: "" };

        // 名前
        ancillaryInfo.accSelected = accInfoItem[i];
        ancillaryInfo.accSelectedNm = accInfoItem[i];

        // 値
        ancillaryInfo.accValue = accValue[i];

        // 付帯情報の名前と値のリストに追加
        ancillaryInfoList.push(ancillaryInfo);
      }

      // 画面表示用付属情報（ツールチップ）
      this.inputList[this.editedIndex].accInfos = accInfos;

      // 付帯情報を設定
      this.inputList[this.editedIndex].accInfoList = ancillaryInfoList;

      // 値渡し用ロケリスト
      this.inputList[this.editedIndex].location = this.location;
      this.inputList[this.editedIndex].locationSid = this.locationSid;

      this.close();
    },

    /**
     * 付帯情報/ロケーション予約POPUP：付帯情報リストボックス取得処理
     */
    changeAcc(value, item) {
      for (var i = 0; i < this.accKeys.length; i++) {
        if (this.accKeys[i].value == value) {
          item.accSelectedNm = this.accKeys[i].text;
          break;
        }
      }
    },

    /**
     * ロケーション予約POPUP閉じる
     */
    closePopup() {
      this.dialogLocation = false;
    },

    /**
     * ロケーション予約画面処理
     */
    addLocation() {
      if (this.inputList[this.editedIndex].warehouse == {}) {
        this.locInfo.warehouse = "";
        this.locInfo.location = "";
        this.locInfo.productCd = "";
        this.locInfo.qty = 0;
        this.locInfo.init = true;
        this.locInfo.referenceDate = dateTimeHelper.convertUTC(
          this.commonInput.arrivalScheduleDate
        );
      } else {
        this.locInfo.warehouse = this.inputList[this.editedIndex].warehouse.warehouse;
        this.locInfo.location = this.inputList[this.editedIndex].warehouse.location;
        this.locInfo.productCd = this.inputList[this.editedIndex].warehouse.productCd;
        this.locInfo.qty = this.inputList[this.editedIndex].warehouse.qty;
        this.locInfo.init = false;
        this.locInfo.referenceDate = dateTimeHelper.convertUTC(
          this.commonInput.arrivalScheduleDate
        );
      }
      this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.commonInput.arrivalScheduleDate);
      this.locInfo.locProductCd = this.inputList[this.editedIndex].productCd;
      this.dialogLocation = true;
    },

    /**
     * 新製品登録POPUP：新製品登録処理
     */
    changeProductNm(value, item, index) {
      // 空の場合
      if (value == null || value == "") {
        item.productList = [];
      }
      if (value == "product0") {
        // 仮登録押下時
        this.dialogNewProduct = true;

        // 仮登録押下した行のインデックスを保持
        this.newProductSelectedIndex = index;

        // バリエーションメッセージを空設定
        this.alertMessageProCd = "";
        this.alertMessageProNm = "";
        this.alertMessageInUnit = "";
        item.search = "";
      } else {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        // 既存品番/品名を選択時
        let val = this.inputList[index].productList.find((v) => v.value == value);
        if (val ?? false) {
          // ロケーションクリア
          item.locationSid = null;
          item.location = null;
          item.locationList = [];
          item.locationHint = null;

          item.productNm = val.name;
          item.productHint = val.text;
          item.isNew = val.isNew;
          item.productList = item.productList.filter((v) => v.value == value);
          // TODO : WMS2022DEV-2562 石井0727（ＰＣ、入荷予定登録）※入荷予定登録で新規品番に対して、倉庫の入力が求められる
          // item.location = val.locationSid;
          // item.warehouse = val.warehouseSid;

          // // 商品の倉庫SIDとロケーションSIDが存在する場合は、倉庫のロケーション情報を取得
          // if (item.warehouse && item.location) {
          //   const locationBiz = getParameter.getLocationBizUse({
          //     searchCategoryDiv: "1",
          //     warehouseSid: item.warehouse,
          //     locationSid: item.location,
          //   });
          //   Promise.all([locationBiz])
          //     .then((result) => {
          //       if (result[0] && result[0][0]) {
          //         item.locationList = result[0]; // ロケーションリスト
          //         item.locationHint = result[0][0].text; // ロケーションNo
          //       }
          //     })
          //     .catch((ex) => {
          //       this.infoDialog.message = ex;
          //       this.infoDialog.title = appConfig.DIALOG.title;
          //       this.infoDialog.isOpen = true;
          //       this.infoDialog.firstPageFlag = true;
          //     });
          // }
        }
      }

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        item.productNm = "";
        item.productHint = "";
        item.serial = "";
        item.dueDate = "";
        item.lotSubkey1 = "";
        item.lotSubkey2 = "";
        item.serialFlg = "0";
        item.lotFlg = "0";
        item.dueDateFlg = "0";
        item.lotSubkey1Flg = "0";
        item.lotSubkey2Flg = "0";
        item.isNew = false;
        item.productQty = null;
        item.lot = null;
        item.caseSu = null;
        item.caseSuHint = null;
        item.ballSu = null;
        item.ballSuHint = null;
        item.baraSu = null;
        item.baraSuHint = null;
        item.locationSid = null;
        item.location = null;
        item.locationHint = null;
        item.warehouseSid = null;
        item.warehouse = null;
        item.locationList = [];

        // トータル数の取得
        var totalNum = 0;
        for (var j = 0; j < this.inputList.length; j++) {
          var proNum = commonUtil.zen2han(this.inputList[j].productQty).replaceAll(",", "");

          // 各行の予定数の合計を計算
          totalNum = Number(totalNum) + Number(proNum);
        }
        // カンマ編集
        this.totalCount = commonUtil.formatToCurrency(Number(totalNum));

        let sumList = [];
        sumList.push({
          No: "",
          productCd: i18n.tc("label.lbl_totalNum") + ":",
          productQty: this.totalCount,
          lot: "",
          caseSu: "",
          ballSu: "",
          baraSu: "",
          warehouse: "",
          location: "",
          accInfos: "",
          deleteData: "",
        });
        this.inputSumList = [];
        this.inputSumList = sumList;
      } else {
        // 処理なし
      }

      this.$refs.editedList.validate();

      // ローディング画面表示OFF
      this.loadingCounter = 0;
    },

    /**
     * 新製品登録POPUP：登録処理
     */
    popNewProductSave() {
      if (!this.$refs.newProductForm.validate()) {
        return;
      }
      let cnt = 0;
      let popInboundUnitQuantityLength = this.popInboundUnitQuantity;

      if (
        popInboundUnitQuantityLength != undefined &&
        popInboundUnitQuantityLength != null &&
        popInboundUnitQuantityLength
      ) {
        popInboundUnitQuantityLength = popInboundUnitQuantityLength.replaceAll(",", "");
      }

      // 入力チェック
      if (!this.popNewProductCd) {
        // 品番未入力時、メッセージ設定
        this.alertMessageProCd = i18n.tc("check.chk_input");
        return;
      }
      if (!this.popNewProductNm) {
        // 品名未入力時、メッセージ設定
        this.alertMessageProNm = i18n.tc("check.chk_input");
        return;
      }
      if (!this.popInboundUnitQuantity) {
        // 入庫単位数未入力時、メッセージ設定
        this.alertMessageInUnit = i18n.tc("check.chk_input");
        return;
      }
      if (popInboundUnitQuantityLength.length > 9) {
        this.alertMessageInUnit = i18n.tc("chk_limitNumLength");
        return;
      }

      // 数値チェック
      if (isNaN(popInboundUnitQuantityLength)) {
        // 入庫単位数が数値でない時
        cnt += 1;
      } else {
        // 処理なし
      }
      // 整数チェック
      if (Number.isInteger(popInboundUnitQuantityLength)) {
        // 入庫単位数が整数でない時
        cnt += 1;
      } else {
        // 処理なし
      }

      if (popInboundUnitQuantityLength.length > 9) {
        return;
      } else if (cnt == 0) {
        // 入力チェックエラー、非表示
        this.alertMessageProCd = "";
        this.alertMessageProNm = "";
        this.alertMessageInUnit = "";

        // 仮登録POSTAPI実施
        const newCode = this.addCdName();

        // 仮登録POSTAPIが成功したら、後続処理実施
        Promise.all([newCode])
          .then(() => {
            // 入庫単位数を数値に変換
            var popIn = this.popInboundUnitQuantity;
            var popInChenge = Number(commonUtil.zen2han(popIn).replaceAll(",", ""));
            var lotFlg = "0";
            var serialFlg = "0";
            var dueDateFlg = "0";
            var lotSubkey1Flg = "0";
            var lotSubkey2Flg = "0";

            if (this.popNewProductLotFlg == "01") {
              lotFlg = "1";
            }
            if (this.popNewProductSerialFlg == "01") {
              serialFlg = "1";
            }
            if (this.popNewProductDueDateFlg == "01") {
              dueDateFlg = "1";
            }
            if (this.popNewProductLotSubkey1Flg == "01") {
              lotSubkey1Flg = "1";
            }
            if (this.popNewProductLotSubkey2Flg == "01") {
              lotSubkey2Flg = "1";
            }
            var itemCd = this.popNewProductCd.substring(0, 50).trim();
            var incidental = this.popNewProductCd.slice(-50).trim();

            this.dummyItemList.push({
              text: itemCd + " " + incidental + "（" + this.popNewProductNm + "）",
              value: this.popNewProductSid,
              name: this.popNewProductNm,
              code: this.popNewProductCd,
              quantity: popInChenge,
              isNew: true,
              serialFlg: serialFlg,
              lotFlg: lotFlg,
              dueDateFlg: dueDateFlg,
              lotSubkey1Flg: lotSubkey1Flg,
              lotSubkey2Flg: lotSubkey2Flg,
            });
            // 仮登録内容を、品番/品名プルダウンに追加
            this.inputList[this.newProductSelectedIndex].productList = this.inputList[
              this.newProductSelectedIndex
            ].productList.concat(this.dummyItemList);

            this.productCd = this.popNewProductSid;
            // 仮登録を選択した部分の値を変更する。（変更しないと仮登録のままなので）
            this.inputList[this.newProductSelectedIndex].productCd = this.popNewProductSid; // ここをSidからCdに変更
            this.inputList[this.newProductSelectedIndex].productHint =
              itemCd + " " + incidental + "（" + this.popNewProductNm + "）";
            //仮登録をした行のロットとS/N、期限日、付属キー１、付属キー２のフラグをセット
            this.inputList[this.newProductSelectedIndex].lotFlg = lotFlg;
            this.inputList[this.newProductSelectedIndex].serialFlg = serialFlg;
            this.inputList[this.newProductSelectedIndex].dueDateFlg = dueDateFlg;
            this.inputList[this.newProductSelectedIndex].lotSubkey1Flg = lotSubkey1Flg;
            this.inputList[this.newProductSelectedIndex].lotSubkey2Flg = lotSubkey2Flg;

            // 仮登録のものの背景を黄色くする。
            this.inputList[this.newProductSelectedIndex].isNew = true;
            // 値をクリアする。
            this.popNewProductNm = "";
            this.popNewProductCd = "";
            this.popNewProductAccessoryCd = "";
            this.popInboundUnitQuantity = "";
            this.popNewProductLotFlg = "";
            this.popNewProductSerialFlg = "";
            this.popNewProductDueDateFlg = "";
            this.popNewProductLotSubkey1Flg = "";
            this.popNewProductLotSubkey2Flg = "";
          })
          .catch(() => {})
          .finally(() => {
            this.$refs.popQuanRef.resetValidation();
            // 入力チェック結果をリセット
            this.$refs.newProductForm.reset();
            this.dialogNewProduct = false;
          });
      }
    },

    /**
     * ロケーション予約画面：登録後処理
     */
    saveLocation(data) {
      // 倉庫名
      this.warehouse = data;
      // 画面表示用ロケーション(ロケーションNo + 倉庫名称表示用)
      this.location = data.location + "（" + data.warehouseNameShort + "）";
      // ロケAPI
      this.getLocation();
      // ロケーションSID
      this.locationSid = data.locationSid;
      // ダイアログを閉じる
      this.dialogLocation = false;
    },

    /**
     * ロケAPI接続
     */
    getLocation() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.locationNo = this.location;

      // 基準日対応
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(
        this.commonInput.arrivalScheduleDate
      );
      // console.debug("getLocation() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_LOCATION, config)
          .then((response) => {
            // console.debug("getLocation() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.locations.forEach((row) => {
                list.push({
                  text: row.locationSid,
                  value: row.locationSid,
                });
              });
              this.locationSidList = list;
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 入荷予定登録画面：登録ボタン押下
     */
    rcvScheAdd() {
      //エラー内容初期化処理--------------------------------------------------
      this.loadingCounter = 1;
      for (let i = 0; i < this.inputList.length; i++) {
        this.inputList[i].requiredItemCheckMsg = i18n.tc("");
        this.inputList[i].warehouseCheckMsg = i18n.tc("");
        this.inputList[i].locationCheckMsg = i18n.tc("");
      }
      this.loadingCounter = 0;
      //エラー内容初期化処理--------------------------------------------------
      let cnt = 0;
      let countCheckFlg = true;
      // 伝票部、入力チェック実施
      if (this.$refs.form.validate() && this.$refs.editedList.validate()) {
        // 明細部未入力チェック準備
        let checkList = []; // 明細部チェックリスト
        for (let i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].productCd) {
            // 明細部の品番品名が入力されている場合はリストに追加
            checkList.push(this.inputList[i].productCd);
          } else {
            // 上記以外の場合は処理なし。
          }
        }

        var errNo = false;
        for (let i = 0; i < this.inputList.length; i++) {
          let count = 0;
          // 品番の入力有無チェック
          if (this.inputList[i].productCd != null && this.inputList[i].productCd != "") {
            count++;
          }
          // 入荷予定数の入力有無チェック
          if (this.inputList[i].productQty != null && this.inputList[i].productQty != "") {
            count++;
          }
          // ロットの入力有無チェック
          if (this.inputList[i].lot != null && this.inputList[i].lot != "") {
            count++;
          }
          if (count > 0) {
            // 必須項目が未入力のチェック
            if (
              this.inputList[i].productCd == null ||
              this.inputList[i].productCd == "" ||
              this.inputList[i].productQty == null ||
              this.inputList[i].productQty == ""
            ) {
              // 未入力の明細Noの取得
              errNo = true;
              // 未入力の必須項目エラーメッセージ
              this.inputList[i].requiredItemCheckMsg = i18n.tc("check.chk_input");
            }
          }
        }

        if (errNo) {
          return;
        }

        // 明細部の未入力チェック
        if (checkList.length <= 0) {
          // 上記でのチェックリストが0件の場合は、警告メッセージ表示
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-RCV-001_006_E");
          cnt = i + 1;
          countCheckFlg = true;
        } else {
          // 処理なし
        }

        // 明細部、入力チェック実施
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].productCd) {
            var checkQtyNum = Number(
              commonUtil.zen2han(this.inputList[i].productQty).replaceAll(",", "")
            );

            if (checkQtyNum == 0) {
              // 入荷予定数が0の場合、警告メッセージ表示
              this.updateDialogMessage = true;
              this.backMessage = messsageUtil.getMessage("P-RCV-001_005_E");
              cnt = i + 1;
              countCheckFlg = true;
            } else {
              // 処理なし
            }

            if (checkQtyNum == "") {
              // 入荷予定数が未入力時
              cnt = i + 1;
            } else {
              // 処理なし
            }

            // 数値チェック
            if (isNaN(checkQtyNum)) {
              // 入荷予定数、ボール数、ケース数、バラ数が数値でない時
              cnt = i + 1;
            } else {
              // 処理なし
            }

            // 整数チェック
            if (Number.isInteger(checkQtyNum)) {
              // 処理なし
            } else {
              // 入荷予定数、ボール数、ケース数、バラ数が整数でない時
              cnt = i + 1;
            }

            // 範囲チェック（0未満）
            if (0 < checkQtyNum) {
              // 処理なし
            } else {
              // 入荷予定数、ボール数、ケース数、バラ数が0未満の場合
              cnt = i + 1;
            }
          }
        }

        // 明細部、入力エラーなし（カウント0）の場合、確認メッセージ表示
        if (cnt == 0 && countCheckFlg) {
          if (this.commonInput.isPaid == "02") {
            this.ConfirmDialog.screenFlag = false;
            this.ConfirmDialog.changeFlag = true;
            this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_004_C");
            this.ConfirmDialog.redMessage = "無償";
            this.ConfirmDialog.title = "警告";
            this.ConfirmDialog.isOpen = true;
          } else {
            this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
            this.ConfirmDialog.isOpen = true;
            this.ConfirmDialog.screenFlag = true;
            this.ConfirmDialog.changeFlag = false;
          }
        } else {
          // 明細部、入力エラー時（カウント1）
          if (this.$refs.editedList.validate()) {
            // 処理なし
          } else {
            for (var j = 0; j < this.inputList.length; j++) {
              let checkPosition = String(j);
              if (!this.inputList[j].productCd) {
                // 関係ないレコードの入力チェックエラーを解除
                eval("this.$refs.productQtyCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.baraSuCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.caseSuCheck" + checkPosition + ".resetValidation()");
                eval("this.$refs.ballSuCheck" + checkPosition + ".resetValidation()");
              } else {
                // 処理なし
              }
            }
          }
        }
      } else {
        // 伝票部、入力チェックエラーの場合、処理なし
      }
    },

    /**
     * 入荷予定登録画面：カーソル位置処理
     */
    setCursor() {
      let listLength = this.inputList.length;
      let cursolPosition = String(listLength - 10);
      eval("this.$refs.cursorProcd" + cursolPosition + ".focus()");
    },

    /**
     * 入荷予定登録画面： 付属情報_商品付属マスタAPI(GET)
     */

    getMaster(item) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      this.editedIndex = this.inputList.indexOf(item);

      // 商品付帯情報
      const itemsIndividual = getParameter.getItemIndividual(
        this.inputList[this.editedIndex].productCd
      );

      Promise.all([itemsIndividual])
        .then((result) => {
          // 画面の初期値を設定します。
          this.accKeys = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 入荷予定登録画面：入荷予定API（POST）
     */
    addRcvSch() {
      if (this.$refs.form.validate()) {
        this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
        this.infoDialog.title = "結果";
        this.infoDialog.isOpen = true;
        this.infoDialog.screenFlag = true;
        this.infoDialog.outsideClickNotCloseFlg = true;
        // 画面遷移ボタン
        this.infoDialog.homePageFlag = true;
        this.infoDialog.rcvSchHacListFlg = true;
        this.infoDialog.rcvSchHacAddFlg = true;
        this.infoDialog.firstPageFlag = false;
      }
    },

    /**
     * 入荷予定登録画面：業務利用商品API（POST）
     */
    addCdName() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();

      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_001;
      body.reqIdv.clientSid = this.commonInput.supplierList.find(
        (element) => element.value === this.commonInput.suppliersSelected
      ).value;

      body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid");
      if (this.popNewProductAccessoryCd) {
        body.reqIdv.itemCd =
          this.popNewProductCd.padEnd(50, " ") + this.popNewProductAccessoryCd.padEnd(50, " ");
      } else {
        body.reqIdv.itemCd = this.popNewProductCd.padEnd(50, " ") + "".padEnd(50, " ");
      }
      body.reqIdv.itemName = this.popNewProductNm;
      body.reqIdv.inboundUnitQuantity = this.popInboundUnitQuantity.replaceAll(",", "");
      body.reqIdv.referenceDatetime = dateTimeHelper.convertUTC(
        this.commonInput.arrivalScheduleDate
      );
      //ロット有無フラグ
      if (this.popNewProductLotFlg == "01") {
        body.reqIdv.lotAircraftnumberFlg = "1";
      } else {
        body.reqIdv.lotAircraftnumberFlg = "0";
      }
      //S/N有無フラグ
      if (this.popNewProductSerialFlg == "01") {
        body.reqIdv.serialAircraftnumberFlg = "1";
      } else {
        body.reqIdv.serialAircraftnumberFlg = "0";
      }
      //期限日有無フラグ
      if (this.popNewProductDueDateFlg == "01") {
        body.reqIdv.dueDateFlg = "1";
      } else {
        body.reqIdv.dueDateFlg = "0";
      }
      //付属キー１有無フラグ
      if (this.popNewProductLotSubkey1Flg == "01") {
        body.reqIdv.lotSubkey1Flg = "1";
      } else {
        body.reqIdv.lotSubkey1Flg = "0";
      }
      //付属キー２有無フラグ
      if (this.popNewProductLotSubkey2Flg == "01") {
        body.reqIdv.lotSubkey2Flg = "1";
      } else {
        body.reqIdv.lotSubkey2Flg = "0";
      }
      // console.debug("addCdName() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_ITEMBIZ, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("addCdName() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.popNewProductNm = jsonData.resIdv.ItemsBizUse.itemName;
              this.popNewProductCd = jsonData.resIdv.ItemsBizUse.itemCd;
              this.popNewProductSid = jsonData.resIdv.ItemsBizUse.itemStandardSid;
              let popInboundUnitQuantityNum = jsonData.resIdv.ItemsBizUse.inboundUnitQuantity;
              this.popInboundUnitQuantity = String(
                commonUtil.formatToCurrency(Number(popInboundUnitQuantityNum))
              );
              resolve(response);
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 仮登録行を追加
     */
    addDummy: function (item) {
      // 取引先が選択されてない場合、処理しない
      if (!this.commonInput.suppliersSelected) {
        return;
      }
      if (item.productList.length == 0) {
        // 選択されてない場合、仮登録追加
        const list = [
          {
            text: i18n.tc("label.lbl_newProductAdd"),
            value: "product0",
            name: i18n.tc("label.lbl_newProductAdd"),
            isNew: true,
          },
        ];
        item.productList = list;
        if (this.dummyItemList.length > 0) {
          item.productList = list.concat(this.dummyItemList);
        }
      }
    },

    /**
     * 倉庫リストボックスセット処理
     */
    checkWarehouse(value) {
      for (var i = 0; i < this.warehouseList.length; i++) {
        if (this.warehouseList[i].value == value.warehouse) {
          return this.warehouseList[i].name;
        }
      }
    },

    /**
     * ロケリストボックスセット処理
     */
    checkLocation(item) {
      return item.locationHint;
    },

    /**
     * 倉庫変更時、ロケ初期化
     */
    changeWarehouse(item, index) {
      item.location = "";
      item.locationHint = "";
      this.inputList[index].locationList = [];
    },

    /**
     * ロケリストボックス変更時メソッド
     */
    changeLocation(value, item, index) {
      // ロケリストから、選択したロケを探す
      let val = this.inputList[index].locationList.find((v) => v.value == value);
      // 値があれば、ロケリストと一致するテキストをいれる
      if ((val ?? false) && value != "") {
        item.locationHint = val.text;
        item.location = val.value;
        // item.locaSearch = val.text;
      }

      // 行のロケリストに一致した内容を全て入れる
      item.locationList = item.locationList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        item.locationSid = null;
        item.location = null;
        item.locationHint = null;
      } else {
        // 処理なし
      }
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.checkDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.checkDialog.isOpen = true;
      this.checkDialog.screenFlag = true;
      this.checkDialog.changeFlag = false;
      this.checkDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      this.commonInput.suppliersSelected = "";
      this.commonInput.isPaid = "01";
      this.commonInput.isBlame = "02";

      // 変更検知用の設定
      this.commonInput.arrivalScheduleDateCal = "";
      // 初期化
      this.init();

      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
    // 商品の有効日時バリデーション
    inputProductValid(item) {
      if (item.productList && item.productList.length > 0) {
        const product = item.productList.find((v) => v.value == item.productCd);
        if (product && this.commonInput.arrivalScheduleDate) {
          const arrivalScheduleDate = new Date(this.commonInput.arrivalScheduleDate);
          const validFrom = new Date(dateTimeHelper.convertUTC2JST(product.validFrom));
          const validTo = new Date(dateTimeHelper.convertUTC2JST(product.validTo));
          // 仮登録商品に対するチェック対象から除外
          if (product.validTo == null) {
            return true;
          }
          // 有効日時が期限内の場合、エラー対象外
          if (validFrom <= arrivalScheduleDate && arrivalScheduleDate <= validTo) {
            return true;
          } else {
            return i18n.tc("check.chk_inputProductFromToValidate");
          }
        }
      }
      return true;
    },
    // ロケーションの有効日時バリデーション
    inputLocationValid(item) {
      if (item.locationList && item.locationList.length > 0) {
        const location = item.locationList.find((v) => v.value == item.location);
        if (location && this.commonInput.arrivalScheduleDate) {
          const arrivalScheduleDate = new Date(this.commonInput.arrivalScheduleDate);
          const validFrom = new Date(dateTimeHelper.convertUTC2JST(location.validFrom));
          const validTo = new Date(dateTimeHelper.convertUTC2JST(location.validTo));
          if (validFrom <= arrivalScheduleDate && arrivalScheduleDate <= validTo) {
            return true;
          } else {
            return i18n.tc("check.chk_inputLocationFromToValidate");
          }
        }
      }
      return true;
    },
    openUpdateDelete(item) {
      if (this.$refs.form.validate()) {
        // // 選択した行のアイテム
        this.dialogDetails = true;
        this.editedIndex = this.inputList.indexOf(item);
        this.detailDialogData.suppliersSelected = this.inputList[this.editedIndex].clientSid;
        this.detailDialogData.suppliersSelectedName = this.getClientName();
        this.detailDialogData.qualityDiv = this.stockList[this.editedIndex].qualityDiv;
        this.detailDialogData.itemName = this.inputList[this.editedIndex].productName;
        this.detailDialogData.itemCd = this.inputList[this.editedIndex].productCnCdShow;
        this.detailDialogData.itemStandardSid = this.inputList[this.editedIndex].itemStandardSid;
      }
    },
    // 詳細情報登録画面をポップアップで開く
    openDetailsAdd(item, index) {
      this.index = index;

      this.openDatailsAdd = true;
    },
    closeDetailsInfo() {
      this.openDatailsAdd = false;
    },
    // 詳細情報登録ボタン押下
    addDetailsInfo() {
      this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
      this.infoDialog.title = "結果";
      this.infoDialog.isOpen = true;
      this.infoDialog.firstPageFlag = true;
      this.openDatailsAdd = false;
    },
  },
  computed: {},
  watch: {
    "commonInput.arrivalScheduleDateCal": {
      handler(val) {
        this.commonInput.arrivalScheduleDate = this.formatDate(val);
      },
      deep: true,
    },
    "commonInput.arrivalScheduleDate": {
      handler() {
        // 入荷予定日の形式チェック
        if (dateTimeHelper.isDisplayDateFormat(this.commonInput.arrivalScheduleDate)) {
          // 入荷予定日がyyyy/MM/dd形式の場合
          // 取引先を取得する処理
          this.getCustomInfo(this.commonInput.arrivalScheduleDate);
          this.locInfo.referenceDate = dateTimeHelper.convertUTC(
            this.commonInput.arrivalScheduleDate
          );
        } else {
          // 上記以外の場合、処理なし
        }
      },
      deep: true,
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    "commonInput.suppliersSelected": function () {
      // 取引先を変更する場合はクリアする
      this.commonInput.arrivalList = [];
      this.commonInput.arrivalSelected = "";
      if (this.inputList.length != 0) {
        this.inputList.splice(0, this.inputList.length);
        for (var j = 0; j < 10; j++) {
          this.defaultItem.No = j + 1;
          this.inputList.push(Object.assign({}, this.defaultItem));
        }
      }
      this.totalCount = 0;
      let sumList = [];
      sumList.push({
        No: "",
        productCd: i18n.tc("label.lbl_totalNum") + ":",
        productQty: this.totalCount,
        lot: "",
        caseSu: "",
        ballSu: "",
        baraSu: "",
        warehouse: "",
        location: "",
        accInfos: "",
        deleteData: "",
      });
      this.inputSumList = [];
      this.inputSumList = sumList;
    },
    "commonInput.isPaid": function (newVal) {
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.commonInput.freeReasonSelected = "";
      }
    },
    "commonInput.arrivalStatusSelected": function (newVal) {
      if (newVal == "02") {
        this.commonInput.isDisabledBlame = false;
        this.commonInput.isBlame = this.commonInput.isBlameBadValue;
      } else {
        this.commonInput.isBlameBadValue = this.commonInput.isBlame;
        this.commonInput.isBlame = "01";
        this.commonInput.isDisabledBlame = true;
      }
    },
    inputList: function (inputL) {
      inputL.forEach((value) => {
        if (value.isWatchAdded === void 0) {
          this.$watch(
            () => value.search,
            (val) => {
              if (val ?? false) {
                console.log("a");
                // 取引先が選択されてない場合、処理しない
                if (!this.commonInput.suppliersSelected) {
                  return;
                }
                // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                if (val == value.productHint) {
                  return;
                }

                // 仮登録の場合変更しない
                if (value.productCd == "product0") {
                  return;
                }
                console.log("b");
                // クリア処理
                value.productCd = "";
                value.productHint = "";
                value.productNm = "";
                value.productHint = "";
                value.isNew = false;
                value.productQty = null;
                value.lot = null;
                value.serial = null;
                value.lotFlg = "0";
                value.serialFlg = "0";
                value.dueDate = null;
                value.dueDateFlg = "0";
                value.lotSubkey1 = null;
                value.lotSubkey1Flg = "0";
                value.lotSubkey2 = null;
                value.lotSubkey2Flg = "0";
                value.caseSu = null;
                value.ballSu = null;
                value.baraSu = null;
                value.locationSid = null;
                value.location = null;
                value.locationHint = null;
                value.warehouseSid = null;
                value.warehouse = null;

                // 入力桁数が定義した数以上の場合検索処理
                if (val.length >= appConfig.CNTCHARITEM) {
                  console.log("c");
                  value.productList = [
                    {
                      text: "08P15-PW8A （パネル）",
                      value: "2201600043",
                      name: "パネル",
                      code: "08P15-PW8A                                                                                          ",
                      isNew: false,
                      serialFlg: "0",
                      lotFlg: "0",
                      dueDateFlg: "0",
                      lotSubkey1Flg: "0",
                      lotSubkey2Flg: "0",
                      quantity: 1,
                    },
                    {
                      text: "08F2039L 0000（KIT,BLACK EMBLEM）",
                      value: "2201600044",
                      name: "KIT,BLACK EMBLEM",
                      code: "08F2039L 0000                                                                                          ",
                      isNew: false,
                      serialFlg: "0",
                      lotFlg: "0",
                      dueDateFlg: "0",
                      lotSubkey1Flg: "0",
                      lotSubkey2Flg: "0",
                      quantity: 1,
                    },
                    {
                      text: "08F213M0 0M03D1（GRILLE ASSY,FR）",
                      value: "2201600045",
                      name: "GRILLE ASSY,FR",
                      code: "08F213M0 0M03D1                                                                                          ",
                      isNew: false,
                      serialFlg: "0",
                      lotFlg: "0",
                      dueDateFlg: "0",
                      lotSubkey1Flg: "0",
                      lotSubkey2Flg: "0",
                      quantity: 1,
                    },
                    {
                      text: "08P1539L 0000（KIT,FROOR MAT）",
                      value: "2201600046",
                      name: "KIT,FROOR MAT",
                      code: "08P1539L 0000                                                                                          ",
                      isNew: false,
                      serialFlg: "0",
                      lotFlg: "0",
                      dueDateFlg: "0",
                      lotSubkey1Flg: "0",
                      lotSubkey2Flg: "0",
                      quantity: 1,
                    },
                    {
                      text: "08P15-PW8A-CM00-04（PLATE）",
                      value: "2201600047",
                      name: "PLATE",
                      code: "08P15-PW8A-CM00-04                                                                                     ",
                      isNew: false,
                      serialFlg: "0",
                      lotFlg: "0",
                      dueDateFlg: "0",
                      lotSubkey1Flg: "0",
                      lotSubkey2Flg: "0",
                      quantity: 1,
                    },
                  ];
                  //   const itemsBiz = getParameter.getItemsBizAddName2(
                  //     this.commonInput.suppliersSelected,
                  //     dateTimeHelper.convertUTC(this.commonInput.arrivalScheduleDate),
                  //     val
                  //   );
                  //   Promise.all([itemsBiz])
                  //     .then((result) => {
                  //       // 画面の初期値を設定します。
                  //       value.productList = result[0];
                  //       // console.debug("inputList: function (inputL) Result[0]", result[0]);
                  //     })
                  //     .catch((ex) => {
                  //       this.infoDialog.message = ex;
                  //       this.infoDialog.title = appConfig.DIALOG.title;
                  //       this.infoDialog.isOpen = true;
                  //       this.infoDialog.firstPageFlag = true;
                  //     })
                  //     .finally(() => {
                  //       // ローディング画面表示OFF
                  //       this.loadingCounter = 0;
                  //     });
                } else {
                  value.productList.length = 0;
                }
              } else {
                this.addDummy(value);
              }
            }
          );
          this.$watch(
            () => value.locaSearch,
            (val) => {
              if (val ?? false) {
                // 取引先が選択されてない場合、処理しない
                if (!this.commonInput.suppliersSelected) {
                  return;
                }

                // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
                if (val == value.locationHint) {
                  return;
                }

                // 入力桁数が定義した数異常の場合検索処理
                if (val.length >= appConfig.CNTCHARITEM) {
                  this.loadingCounter = 1;

                  const locationBiz = getParameter.getLocationBizUse({
                    warehouseSid: value.warehouse,
                    locationNo: val,
                    reqComReferenceDate: dateTimeHelper.convertUTC(
                      this.commonInput.arrivalScheduleDate
                    ),
                  });
                  Promise.all([locationBiz])
                    .then((result) => {
                      value.locationList = result[0];
                    })
                    .catch((ex) => {
                      this.infoDialog.message = ex;
                      this.infoDialog.title = appConfig.DIALOG.title;
                      this.infoDialog.isOpen = true;
                      this.infoDialog.firstPageFlag = true;
                    })
                    .finally(() => {
                      // ローディング画面表示OFF
                      this.loadingCounter = 0;
                    });
                } else {
                  value.locationList.length = 0;
                }
              }
            }
          );
        }
        value.isWatchAdded = true;
      });
    },
    // 入荷元取得イベント
    "commonInput.searchArrival": {
      handler(val) {
        if (val ?? false) {
          // 取引先が選択されてない場合、処理しない
          if (!this.commonInput.suppliersSelected) {
            return;
          }
          // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
          if (val == this.commonInput.productHintArrival) {
            return;
          }
          // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
          if (val.trim().length === 0) {
            return;
          }
          // 入力桁数が定義した数異常の場合検索処理
          if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
            this.commonInput.arrivalList = [
              {
                displayText: "HACT（ホンダアクセス受入）",
                text: "HACT（ホンダアクセス受入）",
                value: "01",
                name: "ホンダアクセス受入",
                clientSid: "01",
                baseCd: "HACT",
              },
            ];
            // 選択した会社コードを取得
            // let officeCd = this.commonInput.supplierList.find(
            //   (element) => element.value === this.commonInput.suppliersSelected
            // ).clientSid;
            // const fromList = getParameter.getRelatedBizPartialMatch(
            //   appConfig.ISGETCLIENT.FROMTO,
            //   dateTimeHelper.convertUTC(this.commonInput.arrivalScheduleDate),
            //   undefined,
            //   officeCd,
            //   val
            // );

            // Promise.all([fromList])
            //   .then((result) => {
            //     // 画面の初期値を設定します。
            //     const list = [];
            //     this.commonInput.arrivalList = list.concat(result[0]);
            //   })
            //   .catch((ex) => {
            //     this.infoDialog.message = ex;
            //     this.infoDialog.title = appConfig.DIALOG.title;
            //     this.infoDialog.isOpen = true;
            //   })
            //   .finally(() => {
            //     // ローディング画面表示OFF
            //     this.loadingCounter = 0;
            //   });
          } else {
            this.commonInput.arrivalList.length = 0;
            this.commonInput.arrivalList = [];
          }
        }
      },
      deep: true,
    },
    "detailDialog.retentionPeriodCal": function (newVal) {
      this.detailDialog.retentionPeriod = this.formatDate(newVal);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

//新製品登録時、色変更
.isD {
  background-color: $color-warning !important;
  //background-color: $color-error !important;
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}
#lbl-user-name {
  // font-size: $menu-fontsize !important;
  font-weight: bold;
  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../../assets/img/user_menu_icon.png");
    background-size: contain;
    vertical-align: middle;
  }
}
#listSumDataSpecial {
  ::-webkit-scrollbar-thumb {
    background: red;
  }

  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child {
      background-color: #eee;
      height: 0rem;
    }

    // グループヘッダー
    .v-row-group__header {
      background-color: #dde5f0;
      height: 0px;
    }

    // データがありません時のスタイル
    .v-data-table__empty-wrapper {
      background-color: #ffffff;
    }

    // テーブルデータ部分
    td {
      font-size: large;

      &:nth-child(1) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(2) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(3) {
        text-align: right;
        width: 11%;
      }

      &:nth-child(4) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(5) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(6) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(7) {
        text-align: right;
        width: 9%;
      }

      &:nth-child(8) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(9) {
        text-align: right;
        width: 10%;
      }

      &:nth-child(10) {
        text-align: right;
        width: 4%;
      }

      &:nth-child(11) {
        text-align: right;
        width: 5%;
      }
    }
  }

  white-space: nowrap;
  pointer-events: none;
  font-weight: 700;
  // overflow-y: scroll;
  // overflow-y: hidden;
}

.tableData {
  border-collapse: collapse;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 12px;
}

.highlight-column {
  background-color: rgb(221, 229, 240);
}

::v-deep .custom-table table {
  border-collapse: collapse !important;
}

.custom-table th,
.custom-table td {
  border: 1px solid rgb(118, 118, 118) !important;
  height: 56px !important;
}

.custom-table thead tr:first-child th {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid rgb(118, 118, 118) !important;
}

.custom-table tr:hover {
  background-color: transparent !important;
}

.right {
  text-align: right;
}

::v-deep .right-align-input .v-input__control .v-input__slot input {
  text-align: right !important;
}
</style>
